.head {
  margin-top: 50px;
  display: flex;
  flex-direction: column;

  .step {
    display: flex;
    flex-direction: row;
  }
}

.content {
  display: flex;
  flex-direction: row;
  gap: 60px;

  @media screen and (max-width: 1366px) {
    flex-direction: column;
    gap: 36px;
  }

  .items {
    width: calc(100% - 520px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media screen and (max-width: 1560px) {
      width: 100%;
    }
  }

  .price_block {
    width: 520px;
    @media screen and (max-width: 1366px) {
      width: 100% !important;
    }
  }
}
