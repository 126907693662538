@import "/src/styles/mixin";

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 24px;
  }
}

.numb,
.numbActive {
  @include font14;
  margin: 0 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: background 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);

  &:hover {
    background-color: var(--primary-color);
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin: 0 3px;
    font-size: 12px;
    line-height: 0;
  }
}

.numbActive {
  background-color: var(--primary-color);
}

.numbNext,
.numbPrev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--blue-background-color);
  transition: background 0.2s ease;

  &:hover {
    background-color: var(--primary-color);
  }
}

.numbNext {
  transform: rotate(180deg);
}

.arrow {
  width: 6px;
}
