@import "/src/styles/mixin";

.wrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 16px;

  .callback_container {
    display: flex;
    flex-direction: column;

    .callback {
      @include font11;
      font-weight: 600;
      text-decoration: underline;
      text-underline-offset: 4px;
      white-space: nowrap;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.text_wrap {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
  padding-right: 16px;
  border-right: 1px solid var(--grey-color);

  .text {
    @include font14Bold;
    white-space: nowrap;
  }

  .time {
    @include font11;
    line-height: 12px;
    font-weight: 600;
    white-space: nowrap;
  }

  @media only screen and (max-width: 768px) and (max-width: 1023.98px) {
    padding-right: 8px;
    margin-right: 8px;
  }
}

.link {
  @include font14Bold;
  white-space: nowrap;
}
