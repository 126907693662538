@import "src/styles/mixin";

.catalog_detail {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding: 10px 40px;
  width: 100%;
  @include customScroll;

  .hero_link {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin-right: auto;
    border-radius: var(--border-radius-small);
    height: 80px;
    min-height: 80px;
    overflow: hidden;
    padding: 16px 16px;
    //background-color: #EDF2F6;

    &_name {
      position: relative;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      z-index: 2;
    }

    &_image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 1;
      //transform: translate(0%, -50%) rotate(-33deg);
    }

    .chevron {
      position: absolute;
      bottom: 8px;
      right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: var(--white-color);
      border-radius: 50%;
      z-index: 2;

      &_icon {
        width: 18px;
        transform: rotate(-90deg);
      }
    }
  }

  &_name {
    font-size: 20px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: 0.01em;
  }

  &_image {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
  }

  &_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    width: 100%;
    column-gap: 30px;

    @media screen and (max-width: 1024px) {
      padding: 0 13px 0 0;
      width: 100%;
      height: 100%;
      max-width: 800px;
      max-height: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 20px 20px;
    }
    @media screen and (max-width: 460px) {
      padding: 0 8px 0 0;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 8px 4px;
    }

    @media screen and (max-width: 375px) {
      grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    }
  }

  &__no_depth {
    //display: flex;
    //flex-wrap: wrap;
    //align-items: flex-start;
    //flex-direction: column;
    grid-template-columns: repeat(2, minmax(240px, 1fr));
    max-width: 520px;

    & > div {
      //flex: 1 0 36%;
      //margin-right: auto;
    }

    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(1, minmax(240px, 1fr));
    }
  }

  .catalog_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .catalog_banners {
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    height: auto;
    gap: 20px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .sales {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px;

    .sale {
      max-width: 420px;
      height: auto;
      transition: all 0.25s ease;
      cursor: pointer;

      .image {
        width: 100%;
        min-height: 236px;
        object-fit: contain;
        transition: inherit;
        border-radius: var(--border-radius-small);
        background-color: var(--grey-light-color);

        @media screen and (max-width: 1024px) {
          min-height: unset;
          height: auto;
        }
      }

      &:hover {
        transform: translateY(-6px);

        .image {
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
        }
      }

      @media screen and (max-width: 1024px) {
        padding-right: 13px;
        max-width: 47%;
      }

      @media screen and (max-width: 550px) {
        display: flex;
        max-width: 100%;
        height: fit-content;
      }

      @media screen and (max-width: 440px) {
        padding-right: 4px;
      }
    }

    @media screen and (max-width: 1024px) {
      gap: 8px;
    }

    @media screen and (max-width: 440px) {
      gap: 4px;
    }
  }

  .catalogs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    max-height: 100%;
    max-width: 800px;
    display: none;
    padding: 0 0;
  }
}

.choice {
  display: flex;
}
