.catalog {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_name {
    padding: 8px 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;
    width: min-content;
    transition: 0.25s ease-in-out;

    &:hover {
      color: var(--blue-color);
    }
  }

  &_elem {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 1;

    &_name {
      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      white-space: nowrap;

      @media screen and (max-width: 1024px) {
        white-space: wrap;
      }

      @media screen and (max-width: 460px) {
        top: 6px;
        left: 6px;
        font-size: 9px;
        line-height: 12px;
      }
    }

    &_image {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
    }
  }

  &_depths {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 20px;

    .more {
      color: var(--grey-third);
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &_elem {
      position: relative;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: -2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--secondary-color);
        transform: translate(-50%, -40%);
      }
    }
  }
}
