.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;

  & > div {
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      height: auto;
      object-fit: contain;
      max-height: 140px;
    }
  }
}
