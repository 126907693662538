@import "/src/styles/mixin";

.panel {
  position: relative;
  flex: 0 1 246px;
  //transition: all .25s ease;

  &Close {
    display: none;
  }

  @media only screen and (min-width: 768px) and (max-width: 1365.98px) {
    width: 100%;
    height: 100%;
    position: fixed !important;
    top: 0 !important;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 52;
    transition:
      opacity 0.2s ease,
      visibility 0.2s ease;

    &Background {
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
    }
    &Wrap {
      position: absolute !important;
      top: 0 !important;
      left: 0;
      width: 420px;
      background-color: var(--white-color);
      height: 100vh;
      padding: 40px 50px;
      overflow-x: hidden;
      transform: translateX(-100%);
      transition: transform 0.2s ease;
    }
    &Close {
      display: block;
      position: absolute;
      top: 26px;
      right: 16px;
      z-index: 1;
    }
    &.isOpen {
      visibility: visible;
      opacity: 1;

      .panelWrap {
        transform: translateX(0);
      }
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}

.panelStickyContainerBottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.panelStickyBottom {
  height: fit-content;
  position: sticky;
  bottom: 0;

  &Bar {
    height: fit-content;
    position: sticky;
    bottom: 56px;
  }
}

.panelStickyTop {
  position: sticky;
  top: 120px;
}
