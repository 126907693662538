.logo {
  max-width: 50px;
  display: flex;
  object-fit: contain;
  height: min-content;
  border-radius: 8px;
  &Wrap {
    margin-right: 16px;
    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      margin-right: 12px;
    }
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      display: none;
    }
  }
}

.link {
  display: block;
}
