@import "/src/styles/mixin";

.wrap {
  margin-bottom: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  background-color: var(--white-color);

  &:hover {
    .img {
      transform: scale(1.02);
    }
  }
}

.link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.label {
  @include font12;
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 0 8px;
  border-radius: var(--border-radius-small);
  background-color: var(--white-color);
  z-index: 1;
}

.img {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  transition: transform 0.7s ease;
  border-radius: var(--border-radius-small);
  aspect-ratio: 57/32;

  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    margin-bottom: 16px;
  }
}

.date {
  @include font14;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.description {
  margin-left: 1px;
}

.title {
  @include font20;
  height: 30px;
  display: -webkit-box;
  margin-bottom: 8px;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text {
  @include font14;
  height: 50px;
  display: -webkit-box;
  margin-bottom: 16px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--dark-grey-color);
  overflow: hidden;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    margin-bottom: 12px;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 2px;
}

.tag {
  @include font14;
  padding: 4px 12px;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  text-transform: lowercase;
  border-radius: var(--border-radius-small);
}
