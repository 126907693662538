.plashka {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 3;

  .image {
    width: 40px;
    height: auto;

    @media screen and (max-width: 767px) {
      opacity: 0.8;
    }
  }
}
