@import "/src/styles/mixin";

.title {
  @include font16Bold;
  margin-bottom: 10px;
}

.categories {
  &__container {
    flex: 0 0 283px;
    @media only screen and (max-width: 1365px) {
      flex: unset;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    //@media only screen and (max-width: 1365px) {
    //  display: none;
    //}
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__link {
    @include font14;
  }
  &__select {
    &__container {
      display: block;
      //@media only screen and (max-width: 1365px) {
      //  display: ;
      //}
    }
  }
}
