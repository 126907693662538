.cardsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
}

.card {
  width: 16%;
  max-width: unset;
  @media only screen and (min-width: 1366px) and (max-width: 1439.98px) {
    width: 19%;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    width: 23.9%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    width: 32.5%;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: 48%;
  }
}
