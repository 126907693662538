@import "/src/styles/mixin";

.card {
  &__point {
    display: flex;
    gap: 12px;

    &_wrap {
      grid-area: 2 / 2 / 3 / 4;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &_info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }

    &_choosed {
      display: flex;
      @include font14Bold;
      overflow: hidden;
      text-align: left;
      flex-direction: column;
      .quantity {
        padding: 4px 8px;
        display: block;
        width: fit-content;
        border-radius: var(--border-radius-small);
        color: var(--black-color);
        background-color: var(--blue-background-color);
      }
      .pointWorkTime {
        color: var(--grey-color);
        font-weight: 400;
      }
    }

    &_schedule {
      font-weight: 500;
      color: var(--grey-color);
    }
  }
}
