.photos {
  &__container {
    position: relative;
    width: 100%;
  }
}

.swiper {
  width: 100%;
  max-width: 450px;

  .slide {
    max-width: 450px !important;
  }
}
