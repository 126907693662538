@import "/src/styles/mixin";

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #e9e9e9;

  &:first-child {
    border-bottom: none;
  }

  &.profileLink {
    justify-content: flex-start;
    gap: 18px;
    align-items: center;
  }

  &.columnLink {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
  }

  .titleSubtitleWrap {
    display: flex;
    flex-direction: column;
  }

  .title {
    @include font14;
    font-weight: 600;
  }

  .subtitle {
    display: flex;
    align-items: center;
    gap: 4px;
    @include font12;
    font-weight: 500 !important;
    color: #9ea0a3;

    &Icon {
      color: #9ea0a3 !important;
    }
  }

  .chevronIcon {
    rotate: -90deg;
  }

  .count {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fee27f;
    display: flex;
    justify-content: center;
    align-items: center;

    &Text {
      font-size: 9px;
      line-height: 8px;
      font-weight: 500;
      text-align: center;
    }
  }
}
