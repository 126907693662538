@import "/src/styles/mixin";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.text {
  @include font14;
}

.link {
  @include contentButton;
  padding: 8px 12px;
}
