@import "/src/styles/mixin";

.price {
  @include font18;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &Old {
    @include font14;
    text-decoration: line-through;
    // @media screen and (max-width: 320px) {
    //   @include font11;
    //   line-height: 22px;
    // }
  }
  &Wrap {
    display: flex;
    flex-direction: column;
  }
  // @media screen and (max-width: 320px) {
  //   @include font14;
  //   margin: 0 0;
  // }
}
