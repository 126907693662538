.wrap {
  display: flex;
  margin-bottom: 60px;
  gap: 50px;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    display: block;
    margin-bottom: 44px;
  }
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    margin-bottom: 0;
  }
}

.cards {
  &Wrap {
    flex: 1 1 auto;
    width: 50%;
    @media only screen and (min-width: 320px) and (max-width: 1365px) {
      width: 100%;
    }
  }
}

.panel {
  position: sticky;
  top: 130px;
  height: 100%;
  flex: 0 1 246px;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    position: relative;
    top: 0;
  }
}

.button {
  padding: 12px 18px;
  display: block;
  margin: auto;
}
