@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  width: 213px;
  gap: 4px;

  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    transition: all 500ms;
    padding: 0 5px;

    &:hover {
      background-color: #edf2f6;
    }

    .text {
      @include font14;
      font-weight: 500;
      line-height: 28px;
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 20px;
      height: 20px;
      background-color: #ffcc00;
      border-radius: 50%;
      @include font14;
      font-weight: 500;
      line-height: 28px;
    }
  }
}
