@import "/src/styles/mixin";

.categories {
  margin-bottom: 50px;
  &List {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 280px));
    gap: 66px;
    justify-content: space-between;
    @media only screen and (min-width: 1440px) and (max-width: 1559.98px) {
      grid-template-columns: repeat(auto-fit, minmax(auto, 250px));
    }
    @media only screen and (min-width: 1366px) and (max-width: 1439.98px) {
      grid-template-columns: repeat(auto-fit, minmax(auto, 230px));
    }
    @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
      grid-template-columns: repeat(auto-fit, minmax(350px, auto));
      gap: 64px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, auto));
    }
  }
  &Item {
    &Title {
      @include font16Bold;
      position: relative;
      padding-bottom: 14px;
      margin-bottom: 14px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 40px;
        background-color: var(--black-color);
      }
    }
    &Text {
      @include font14;
    }
  }
}
