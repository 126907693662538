@import "/src/styles/mixin";

.wrap {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: block;
  }

  .modal_container {
    position: relative;
  }
}

.list {
  padding: 0 20px;
  margin: 0 12px 20px;
  display: flex;
  justify-content: space-between;
  background: radial-gradient(circle at 50% 10px, transparent calc(35px), #fff 8px);
  border-radius: 50px;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.5));
}

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 9px;
  line-height: 1.15;
}

.itemButton {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-16px);
  background-color: var(--white-color);
  border-radius: 50%;
}

.item {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  bottom: 10px;
  right: 0;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  color: var(--black-color);
  background-color: var(--primary-color);
  font-size: 10px;
}
