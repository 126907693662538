.download {
  .list {
    display: flex;
    align-items: center;
    gap: 16px;

    .item {
      border-radius: var(--border-radius-small);
      border: 1px solid var(--grey-color);
      transition: border-color 0.2s ease;

      .link {
        display: flex;
        padding: 16px 18px;
      }

      &:hover {
        border-color: var(--white-color);
      }
    }
  }
}
