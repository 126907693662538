@import "/src/styles/mixin";

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  max-width: 496px;

  .linksWrap {
    display: flex;
    width: 100%;
    gap: 10px;
  }
}

.buttons_wrap {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;

  .button,
  .delete_button {
    @include font14;
    text-align: left;
    transition: color 0.2s ease;
    cursor: pointer;

    &:hover {
      color: var(--grey-color);
    }
  }

  .delete_button {
    opacity: 0.35;

    &:hover {
      color: var(--grey-color);
    }
  }
}
