@import "../../../../../styles/mixin";

.review {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px;
  border: 1px solid var(--blue-background-color);
  border-radius: var(--border-radius-normal);
  transition: 0.25s ease;
  max-width: 457px;
  background-color: var(--white-color);

  &_product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
    border-bottom: 1px dashed var(--grey-darker-color);
    padding-bottom: 28px;

    .product {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .stars {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }

      .text {
        @include font14;
        color: var(--grey-darker-color);
        white-space: nowrap;
      }

      &_name {
        @include font14;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .image {
      width: 100%;
      max-width: 90px;
      height: 90px;
      mix-blend-mode: multiply;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }

  &_info {
    width: 100%;
    overflow: hidden;
    position: relative;

    .container {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 24px;
      transition: 0.25s ease-in-out;

      .elem {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 409px;

        .name {
          @include font20;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .text {
          @include font14;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-clamp: 3;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      margin: 12px auto;
      justify-content: center;

      .prev_button,
      .next_button {
        //position: absolute;
        //top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;

        background-color: var(--white-color);
        border-radius: 50%;
        z-index: 2;
      }

      .prev_button {
        //left: 20px;
        transform: rotate(90deg);
      }

      .next_button {
        //right: 20px;
        transform: rotate(-90deg);
      }
    }
  }

  &:hover {
    box-shadow: 0px 1px 4px #00000029;
  }
}
