@import "/src/styles/mixin";

.button {
  @include font14Bold;
  display: flex;
  align-items: center;
  gap: 8px;

  &.inActive {
    cursor: default;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}
