@import "/src/styles/mixin";

.card {
  &__point {
    // &_choose {
    //   &_button {
    //     // &:focus {
    //     //   &~.card__point_list {
    //     //     visibility: visible;
    //     //     opacity: 1;
    //     //     transform: translateY(5px);
    //     //   }
    //     // }
    //   }
    // }

    &_choosed {
      &_wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        min-height: 48px;
      }
    }

    &_list {
      position: absolute;
      padding: 20px;
      top: 45%;
      left: 0;
      right: 0;
      background-color: var(--white-color);
      box-shadow: 4px 4px 35px rgba(0, 0, 0, 0.08);
      border-radius: var(--border-radius-small);
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      transition:
        opacity 0.2s ease,
        visibility 0.2s ease,
        transform 0.2s ease;

      &_active {
        position: absolute;
        padding: 20px;
        top: 45%;
        left: 0;
        right: 0;
        background-color: var(--white-color);
        box-shadow: 4px 4px 35px rgba(0, 0, 0, 0.08);
        border-radius: var(--border-radius-small);
        z-index: 1;
        transition:
          opacity 0.2s ease,
          visibility 0.2s ease,
          transform 0.2s ease;
        visibility: visible;
        opacity: 1;
        transform: translateY(5px);
      }
    }
  }
}
