@import "/src/styles/mixin";

.review {
  &__list {
    margin-bottom: 24px;
  }

  &__item {
    padding: 24px 0;
    display: flex;
    gap: 40px;
    background-color: var(--white-color);

    &:not(:last-child) {
      border-bottom: 1px solid var(--grey-lines-color);
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      flex-direction: column;
      gap: 24px;
    }
  }

  &__content {
    width: 100%;
    flex: 0 2 auto;
  }

  &__title {
    @include font18;
    margin-bottom: 24px;
  }

  &__link {
    @include font14;
    display: inline-block;
    padding: 8px 24px;
    border-radius: var(--border-radius-small);
    border: 1px solid var(--secondary-color);
  }

  &__card {
    flex: 0 1 235px;
    width: 100%;
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      width: unset;
      flex: unset;
    }
  }
}
