@import "/src/styles/mixin";

.section {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  @media only screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 16px;
  }
}

.image {
  object-fit: contain;
  &__container {
    width: 100px;
    height: 100px;
    margin-right: 40px;
    @media only screen and (max-width: 1365px) {
      margin-right: 20px;
    }
    @media only screen and (max-width: 767.98px) {
      margin-right: 10px;
    }
  }
}

.text {
  @include font14;
  &__container {
    margin-right: 40px;
    &_links {
      display: flex;
      gap: 12px;
    }
    @media only screen and (max-width: 1365px) {
      margin-right: 20px;
    }
  }
}

.code {
  @include font14;
  margin-bottom: 4px;
  color: var(--grey-color);
}

.name {
  @include font16Bold;
  margin-bottom: 4px;
  @media only screen and (max-width: 767.98px) {
    @include font14;
  }
}

.link {
  @include font14;
  @include dashedBottomLine;
}

.dropdownButton {
  position: relative;

  &Text {
    border-bottom: 1px dashed var(--grey-color);
    display: flex;
    align-items: center;
    gap: 8px;
    @include font14;
  }
}

.icon {
  width: 18px;
  height: 18px;
}

.prices {
  &__container {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    @media only screen and (max-width: 1365px) {
      margin-right: 20px;
    }
    @media only screen and (max-width: 767.98px) {
      margin-left: unset;
    }
  }
}

.price {
  @include font24;
  white-space: nowrap;
  &__old {
    @include font14;
    white-space: nowrap;
    text-decoration-line: line-through;
  }
}

.button {
  &__container {
    white-space: nowrap;
  }
}
