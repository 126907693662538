@import "/src/styles/mixin";

.geo {
  display: flex;
  align-items: center;
  gap: 6px;
}
.button {
  @include font14;
  @include dashedBottomLine;
}
