.wrap {
  display: flex;
  align-items: center;
  gap: 24px;

  .desktop {
    display: inherit;
    align-items: inherit;
    gap: inherit;

    .hint {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .registerText {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    width: 202px;
  }
}
