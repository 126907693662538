.labels {
  position: absolute;
  top: 0;
  left: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  z-index: 2;
  @media only screen and (min-width: 1440px) and (max-width: 1560px) {
    left: 100px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
    left: 80px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    left: 70px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    position: relative;
    margin-top: 16px;
    left: 0;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.inModal {
    top: auto;
    bottom: 0;
    left: 108px;
    flex-direction: row;
    max-width: 293px;
    flex-wrap: wrap;
    z-index: 2;

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      position: relative;
      margin-top: 16px;
      left: 0;
    }
  }
}
