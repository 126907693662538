.nav {
  margin: 24px 0;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin: 12px 0;
  }
}

.list {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
