@use "src/styles/mixins/ff" as *;
@import "src/styles/mixin";

.list {
  position: relative;
  display: flex;
  min-width: 292px;
  flex-direction: column;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;
  @include customScroll;

  .catalog {
    @include ff(16px, 400, 24px);
    padding: 8px;
    cursor: pointer;
    transition: all 0.25s ease;
    position: relative;

    &_name {
      position: relative;
      white-space: nowrap;
      font-weight: 400;
      z-index: 1;

      @media screen and (max-width: 1024px) {
        position: absolute;
        top: 10px;
        left: 8px;
        font-size: 14px;
        line-height: 16px;
        white-space: wrap;
      }

      @media screen and (max-width: 460px) {
        top: 6px;
        left: 6px;
        font-size: 10px;
        line-height: 12px;
      }
    }

    &_image {
      max-width: 147px;
      height: 147px;
      aspect-ratio: 1;
      object-fit: contain;
      background-color: #edf2f6;
      border-radius: var(--border-radius-small);

      @media screen and (max-width: 1024px) {
        height: auto;
        max-width: 100%;
      }
    }

    &:hover {
      background-color: #edf2f6;

      @media screen and (max-width: 1024px) {
        &:hover {
          background-color: transparent;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 0;
      aspect-ratio: 1;
      width: 100%;
      background-color: #edf2f6;
      border-radius: var(--border-radius-small);
    }
  }

  .sale {
    position: relative;

    .sale_icon {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-50%);
      z-index: 0;

      [data-background] {
        transition: all 0.15s ease;
        fill: var(--yellow-10);
      }
    }

    &.active {
      background-color: transparent;

      .sale_icon {
        [data-background] {
          fill: var(--yellow-color-200);
        }
      }
    }

    &:hover {
      background-color: transparent;

      .sale_icon {
        [data-background] {
          fill: var(--yellow-color-200);
        }
      }
    }

    @media screen and (max-width: 1024px) {
      background-image: url("/images/pictures/catalog_sale_detail.png");
      background-size: 500% 100%;
      background-position: 80% 100%;

      .sale_icon {
        display: none;
      }
    }
  }

  .active {
    background-color: #edf2f6;

    @media screen and (max-width: 1024px) {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0 13px 0 0;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 9px 16px;

    @media screen and (max-width: 460px) {
      padding: 0 8px 0 0;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 1px 6px;
    }
    @media screen and (max-width: 375px) {
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    }
  }
}

.hidden {
  display: none;
}
