@import "/src/styles/mixin";

.title {
  @include font14Bold;
  margin-bottom: 12px;
}

.button {
  @include font14;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  transition: background 0.2s ease;
  &Text {
    max-width: 380px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &Wrap {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &Close {
    display: flex;
    margin-left: auto;
  }
  &List {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &:hover {
    background-color: var(--blue-background-color);
  }
}

.icon {
  width: 12px;
  height: 12px;
  path {
    fill: #7f7f7f;
  }
}
