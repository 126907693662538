@import "/src/styles/mixin";

.wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }

  .company {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 15px 0px 15px 4px;
    border-bottom: 1px solid var(--grey-lines-color);
    column-gap: 28px;
    row-gap: 12px;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 24px;

      .company_image_wrap {
        display: flex;
        align-items: center;
        width: auto;
        justify-content: space-between;
        gap: 24px;
        grid-area: 1 / 1 / 3 / 5;

        @media (min-width: 1025px) and (max-width: 1150px) {
          grid-area: 1 / 1 / 3 / 5;
          flex-direction: column;
          align-items: flex-start;
        }

        @media screen and (max-width: 500px) {
          grid-area: 1 / 1 / 3 / 5;
          flex-direction: column;
          align-items: flex-start;
        }

        .company_image {
          width: 94px;
          height: auto;
        }
      }

      .changeButton {
        font-weight: 400;
        padding: 4px 36px;
        grid-area: 1 / 5 / 3 / 5;

        @media screen and (max-width: 500px) {
          grid-area: 1 / 4 / 2 / 5;
          padding: 0 0;
        }
      }

      .button {
        @include font14;
        @include contentButton;
        font-weight: 600;
        padding: 4px 36px;
        border-radius: var(--border-radius-small);
        grid-area: 1 / 4 / 3 / 5;
        height: fit-content;

        @media screen and (max-width: 500px) {
          grid-area: 1 / 4 / 2 / 5;
        }
      }
    }

    .active {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      width: 100%;
      height: fit-content;
      gap: 24px;
      align-items: center;

      @media screen and (max-width: 500px) {
        align-items: flex-start;
      }
    }
  }

  .choosedPoint {
    display: flex;
    align-items: center;
    margin-left: 36px;
    justify-content: space-between;
    width: 100%;
    max-width: 402px;
    gap: 24px;
    position: relative;
    min-height: 48px;
  }
}
