@import "src/styles/mixin";

.modal_container {
  padding: 0 0;
}

.container {
  width: 100%;
  max-width: 450px;

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      @include font20;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-weight: 800;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .custom_input {
        background-color: var(--blue-background-color);
        border: none;
        padding-bottom: 12px;
        &::placeholder {
          @include font14;
          color: var(--grey-third);
        }
      }

      .policy {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &_item {
          display: flex;
          flex-direction: row;
        }
      }

      .buy_button {
        @include contentButton;
        padding: 12px 24px;
      }
    }
  }
}

.birthdayText {
  @include font12;
  font-style: italic;
  font-weight: 500;
}
