@import "/src/styles/mixin";

.compressed {
  width: auto;
  display: flex;
  gap: 24px;
  border-radius: var(--border-radius-small);
  padding: 8px 0;
  max-width: 142px;
  max-height: 142px;

  .card_photo {
    border-radius: var(--border-radius-small);
    background-color: var(--blue-background-color);
    aspect-ratio: 1/1;
    mix-blend-mode: multiply;
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding: 8px;
    max-width: 134px;
    max-height: 134px;

    .image {
      aspect-ratio: 1/1;
      mix-blend-mode: multiply;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    @media screen and (max-width: 425px) {
      max-width: 85px;
      max-height: 85px;
    }
  }

  .card_info {
    display: none;
  }
}

.extended {
  width: 100%;
  display: flex;
  gap: 24px;
  border-radius: var(--border-radius-small);
  padding: 12px 0;

  .card_photo {
    border-radius: var(--border-radius-small);
    background-color: var(--blue-background-color);
    aspect-ratio: 1/1;
    mix-blend-mode: multiply;
    height: 100%;
    width: 100%;
    object-fit: contain;
    padding: 8px;
    max-width: 150px;
    max-height: 150px;

    .image {
      aspect-ratio: 1/1;
      mix-blend-mode: multiply;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .card_info {
    width: 100%;

    .card_name {
      @include font14;
    }
    .card_code {
      @include font12;
      font-weight: 500;
      color: var(--grey-color);
    }
  }
}
