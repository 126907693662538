.stories_wrap {
  position: relative;

  .skeleton {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: calc(100% - 16px);
    width: 100%;
    z-index: 2;
  }
}
