@import "/src/styles/mixin";

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 180px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
  }
}

.panel {
  padding: 0 0 24px 0;
}

.input {
  padding: 16px 20px 8px;
  border: none;

  &Wrap {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    flex-wrap: nowrap;

    .clear {
      padding: 4px 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &_icon {
        width: 14px;
        height: 14px;
      }
    }
  }
}
