@import "/src/styles/mixin";

.text {
  @include font14;
  &Wrap {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 8px;
  }
}

.title {
  @include font14;
}

.more {
  @include font14;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed var(--grey-darker-color);
    transition: color 0.2s ease;
  }
}

.button {
  @include font14;
  padding: 0 8px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  span {
    @include font14;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid var(--secondary-color);
    width: 20px;
    height: 20px;
  }
}
