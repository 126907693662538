.img {
  height: 100%;
}

.list {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    gap: 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 12px;
    gap: 8px;
    flex-direction: column;
  }
}
