.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  height: 100svh;
  background-color: var(--secondary-color);
  color: var(--white-color);
  text-align: center;
  padding: 24px 24px;

  .not_redirect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 12px;

    .link {
      padding: 8px 16px;
      background-color: var(--white-color);
      border-radius: var(--border-radius-small);
      transition: all 0.25s ease;

      &:hover {
        background-color: var(--bar-3);
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }
}
