@import "/src/styles/mixin";

@keyframes slideInDesktop {
  from {
    height: 0;
    padding: 0;
  }
  to {
    height: 60px;
    padding: 6px 39px;
  }
}

@keyframes slideInMobile {
  from {
    height: 0;
    padding: 0;
  }
  to {
    height: 52px;
    padding: 5px 20px;
  }
}

.wrap {
  width: 100%;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ffcc00;
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;
  height: 0;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    z-index: 0;
  }

  &VisibleDesktop {
    display: flex;
    cursor: pointer;
    animation: slideInDesktop 0.3s ease forwards;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &VisibleMobile {
    @media screen and (max-width: 767px) {
      display: flex;
      cursor: pointer;
      position: relative;
      animation: slideInMobile 0.3s ease forwards;
    }
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 767px) {
    gap: 6px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.textMain {
  @include font16;
  line-height: 20px;

  @media screen and (max-width: 767px) {
    @include font13;
    font-weight: 500;
    line-height: 22px;
  }
}

.textSmall {
  @include font14;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffffff;
}

.icon {
  rotate: -90deg;
}

.closeButton {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  z-index: 20;

  @media screen and (max-width: 767px) {
    top: 8px;
    right: 8px;
    z-index: 0;
  }
}
