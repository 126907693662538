@import "/src/styles/mixin";

.container {
  position: relative;
  width: 100%;
  max-width: 382px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px;
  border: 1px dashed var(--grey-lines-color);
  border-radius: var(--border-radius-normal);
  background-color: var(--white-color);
  min-height: 220px;

  @media screen and (min-height: 320px) and (max-width: 767px) {
    min-height: 150px;
  }
}

.title {
  @include font14;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  max-width: 380px;

  &__info {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &__add {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;
  }
}

.blured {
  &__container {
    position: relative;
    text-align: center;
  }
  &__content {
    filter: blur(6px);
  }
  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 8px 12px;
  }
}

.link {
  @include font14;
  color: var(--blue-color);
}

.info {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 6px;
  width: auto;
  min-width: 250px;
  max-width: 350px;

  .title {
    @include font20;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 800;
  }

  ol {
    margin: 0 0;
  }

  .text {
    @include font14;

    span {
      font-weight: 800;
    }
  }
}

.button {
  @include font14;
  padding: 12px 16px;
  background-color: var(--primary-color);
  border-radius: var(--border-radius-small);
  width: 90%;

  @media screen and (min-height: 320px) and (max-width: 767px) {
    width: 100%;
  }
}
