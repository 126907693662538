@import "/src/styles/mixin";

.order {
  &Form {
    display: flex;
    width: 100%;
    max-width: 730px;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px 36px;
  }

  &Input {
    &Wrap {
      width: 100%;

      &:nth-child(3),
      &:nth-child(4) {
        width: 100%;
      }
    }
  }
}
