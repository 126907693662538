.link {
  display: flex;
  max-width: 400px;
  max-height: 230px;
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: var(--border-radius-small);

  &_banner {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
