@import "/src/styles/mixin";

.container {
  position: relative;
  display: flex;
  gap: 20px;

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 335px;
    z-index: 2;
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: block;
  }
}

.photos {
  &__more {
    @include font14;
    color: var(--blue-color);
  }

  &__container {
    width: 100px;
    flex-shrink: 0;
    @media only screen and (min-width: 1440px) and (max-width: 1560px) {
      width: 80px;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
      width: 60px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      width: 50px;
    }
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &_disabled {
      display: none;
    }
  }

  &__btn {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-small);
    cursor: pointer;
    border: 1px solid transparent;
    transition: border-color 0.2s ease;
    background-color: var(--white-color);
    aspect-ratio: 1 / 1;

    &_active {
      border-color: var(--grey-lines-color);
    }
  }

  &__img {
    padding: 12px;
    display: block;
    width: 100px;
    height: 100px;
    object-fit: contain;

    @media only screen and (min-width: 1440px) and (max-width: 1560px) {
      width: 80px;
      height: 80px;
    }
    @media only screen and (min-width: 1024px) and (max-width: 1439.98px) {
      width: 60px;
      height: 60px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
      width: 50px;
      height: 50px;
    }
  }
}

.photo {
  &__img {
    width: 100%;
    height: 100%;
    max-height: 588px;
    max-width: 588px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    cursor: zoom-in;
    @media only screen and (min-width: 768px) and (max-width: 1560px) {
      max-height: 450px;
      max-width: 450px;
    }
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      max-height: unset;
      max-width: unset;
      object-fit: contain;
    }
  }

  &__container {
    width: 100%; // проверить
    max-width: 450px; // и если что убрать
    background-color: var(--white-color);

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      display: none;
    }
  }
}

.swiper {
  display: none !important;
  z-index: 1;
  @media screen and (max-width: 767px) {
    display: block !important;
    height: 335px;
    padding-bottom: 35px;
  }
}
