.wrap {
  position: relative;

  .button {
    display: flex;

    .icon {
      & > path {
        stroke: var(--secondary-color);
      }
    }

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      bottom: -4px;
      right: -4px;
      width: 14px;
      height: 14px;
      border-radius: 100px;
      color: var(--black-color);
      background-color: var(--primary-color);
      font-size: 10px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: none;
  }
}
