@import "/src/styles/mixin";

.modal {
  padding: 40px 29px !important;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100% !important;
    top: auto !important;
    bottom: -168px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.container {
  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 262px;

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &Duration {
    @include font14;
    color: #909090;
    font-weight: 600;
  }

  &Title {
    @include font16;
    color: #495057;
  }

  &Condition {
    @include font12;
    font-weight: 500 !important;
  }
}

.button {
  @include contentButton;
  width: 100%;

  &TextContainer {
    margin-right: 12px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
