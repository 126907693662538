@import "/src/styles/mixin";

.bulleted_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: disc !important;
  padding: 0 0 0 44px;
  margin-bottom: 12px;
}

.numbered_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
}

.list_item {
  @include font16;
}

.first_heading {
  @include font24;
}

.second_heading {
  @include font20;
  margin-bottom: 24px;
}

.link {
  font-weight: 800;
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
  transition: all 0.25s;
}

.link:hover {
  color: var(--primary-color);
}

.text {
  @include font16;
  margin-bottom: 12px;
}
