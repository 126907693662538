@import "/src/styles/mixin";

.actions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  margin-top: 24px;

  &_button {
    padding: 4px 8px;
    font-size: 16px;
    border: 1px solid var(--secondary-color);
    border-radius: var(--border-radius-small);
  }
}

.streamerseContainer {
  position: unset;
  z-index: 1;
}

.title {
  @include font30;
}
