@import "src/styles/mixin";

.custom_select {
  width: 100%;
  border-radius: var(--border-radius-small);
  position: relative;
  cursor: pointer;

  .select_title {
    @include font14;
    padding: 12px 16px;
    position: relative;
  }

  .select_title::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translate(0, -50%) rotate(180deg);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent rgb(29, 29, 29) transparent;
  }

  .select {
    background-color: var(--white-color);
    border-radius: var(--border-radius-small);
    border: 1px solid var(--grey-lines-color);
    display: flex;
    flex-direction: column;
    transition: 0.2s ease;
    min-width: fit-content;
    max-height: 280px;
    overflow-y: auto;

    .select_elem {
      padding: 13px 16px;
      @include font14Bold;
      cursor: pointer;
    }

    .select_elem:hover {
      background-color: var(--blue-background-color);
    }

    @media screen and (max-width: 425px) {
      max-height: 200px;
    }
  }

  .select_open {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 3;
  }

  .select_hidden {
    position: absolute;
    top: 90%;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    //z-index: -1;
    z-index: 3;
  }
}

.border {
  border: 1px solid var(--grey-lines-color);
  background-color: var(--white-color);
}

.background {
  background-color: var(--blue-background-color);
  border: 1px solid transparent;
}
