@import "src/styles/mixin";

.notice {
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    gap: 8px;
  }

  &NewMarker {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--primary-color);
    flex-shrink: 0;
    margin-top: 37px;

    @media screen and (min-width: 320px) and (max-width: 767px) {
      margin-top: 13px;
    }
  }

  &NoNewMarker {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
  }

  &Icon {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background-color: #fee27f;
    flex-shrink: 0;
  }

  &TextContainer {
    max-width: 450px;
    display: flex;
    flex-direction: column;
  }

  &Title {
    @include font18;
    line-height: 32px !important;
  }

  &Text {
    @include font16;
  }

  &Extra {
    @include font13;
    font-weight: 500 !important;
    line-height: 13px !important;
    color: #909090;
  }

  &Link {
    @include font16;
    color: #105ca8;
    cursor: pointer;
  }

  &Copy {
    display: flex;
    gap: 10px;
    margin-left: auto;
    align-items: center;

    &Button {
      @include contentButton;
      width: 135px;

      &Text {
        padding: 12px 0;
        @include font14;
      }
    }

    &Info {
      cursor: pointer;
    }
  }

  &CodeExpired {
    color: #9ea0a3;

    &Right {
      margin-left: auto;
    }
  }
}
