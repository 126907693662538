@import "/src/styles/mixin";

.wrap {
  padding: 0 0 24px 0;
  background-color: var(--white-color);
}

.title {
  @include font16Bold;
  margin-bottom: 12px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-height: 500px;
  overflow-x: hidden;
}

.link {
  @include font14;
  @include lineHover;

  &Back {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &Active {
    border-bottom: 1px solid var(--secondary-color);
  }
}

.text {
  @include font14;
  padding: 2px 12px;
  background-color: var(--grey-lines-color);
  text-align: left;
  border-radius: 100px;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}
