@import "../../../../../styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;

  .reviews {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 64px 0 32px;

    .subtitle {
      @include font24;
      margin-right: 16px;
    }

    .deeplink {
      @include font14;
      color: var(--grey-darker-color);
      // margin-right: 4px;
    }

    .count {
      @include font12;
      color: var(--grey-darker-color);
    }
  }

  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, max-content));
    gap: 35px;
  }
}
