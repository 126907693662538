@import "/src/styles/mixin";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    transition: all 500ms;
    padding: 0 5px;

    &:hover {
      background-color: #edf2f6;
    }

    .text {
      @include font14;
      font-weight: 500;
      line-height: 28px;
      text-wrap: nowrap;
    }
  }
}
