@import "../../../../../styles/mixin";

.tabs {
  width: 100%;
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  padding: 10px 0;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &Item {
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    padding: 4px 10px;
    flex-wrap: nowrap;
    border: 1px solid transparent;
    &Active {
      border: 1px solid var(--primary-color);
      border-radius: 16px;
    }

    &Text {
      @include font13;
      line-height: 22px !important;
      font-weight: 500 !important;
      text-wrap: nowrap;
      user-select: none;
    }

    &Count {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font11;
      font-weight: 500 !important;
      line-height: normal;
      background-color: var(--primary-color);
      border-radius: 50%;
      text-align: center;
      flex-shrink: 0;
    }
  }
}
