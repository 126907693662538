@import "/src/styles/mixin";

.wrap {
  display: none;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: block;
    margin-bottom: 24px;
  }
}

.button {
  @include font14;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background-color: var(--blue-background-color);
  border-radius: var(--border-radius-small);
}

.icon {
  transform: rotate(-180deg);
  margin-left: auto;
}
